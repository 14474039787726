// src/api.js
export async function fetchPricesAndWeather(zipCode) {
  try {
    const response = await fetch(
      `https://heat-oil-predict.stephen-lamari.workers.dev/?zip=${zipCode}`
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return {
      isGoodTime: Math.random() > 0.5, // Mocking AI prediction for now
      prices: data.prices,
      weatherData: data.weatherData, // Ensure this is an array of objects with date and temperature
    };
  } catch (error) {
    console.error("Failed to fetch data:", error);
    return null;
  }
}
