// src/ChartComponent.js
import React from "react";
import { Line } from "react-chartjs-2";
import "chart.js/auto";

const ChartComponent = ({ label, data }) => {
  if (!data || data.length === 0) {
    return <p>No data available</p>;
  }

  let chartData;
  let options;

  if (label === "Temperature") {
    const labels = data.map((d) => d.date);
    const temperatures = data.map((d) => d.temperature);

    chartData = {
      labels: labels,
      datasets: [
        {
          label: label,
          data: temperatures,
          backgroundColor: "rgba(0, 123, 255, 0.5)",
          borderColor: "rgba(0, 123, 255, 1)",
          borderWidth: 1,
        },
      ],
    };

    options = {
      scales: {
        y: {
          beginAtZero: false,
          ticks: {
            callback: function (value) {
              return value + "°F";
            },
          },
          title: {
            display: true,
            text: "Temperature (°F)",
          },
        },
        x: {
          title: {
            display: true,
            text: "Date",
          },
        },
      },
    };
  } else {
    // Extract data for each payment method
    const cashData = data.filter((d) => d.payment_method === "cash");
    const creditData = data.filter((d) => d.payment_method === "credit");

    // Get unique gallon tiers for labels
    const labels = [...new Set(data.map((d) => d.gallons))];

    chartData = {
      labels: labels,
      datasets: [
        {
          label: "Cash Prices",
          data: labels.map((label) => {
            const item = cashData.find((d) => d.gallons === label);
            return item ? item.price : null;
          }),
          backgroundColor: "rgba(0, 123, 255, 0.5)",
          borderColor: "rgba(0, 123, 255, 1)",
          borderWidth: 1,
        },
        {
          label: "Credit Prices",
          data: labels.map((label) => {
            const item = creditData.find((d) => d.gallons === label);
            return item ? item.price : null;
          }),
          backgroundColor: "rgba(255, 99, 132, 0.5)",
          borderColor: "rgba(255, 99, 132, 1)",
          borderWidth: 1,
        },
      ],
    };

    options = {
      scales: {
        x: {
          title: {
            display: true,
            text: "Gallons",
          },
        },
        y: {
          beginAtZero: false,
          ticks: {
            callback: function (value) {
              return "$" + value.toFixed(2);
            },
          },
          title: {
            display: true,
            text: "Price per gallon ($)",
          },
        },
      },
    };
  }

  console.log("Chart data:", chartData); // Debugging log

  return <Line data={chartData} options={options} />;
};

export default ChartComponent;
