// src/App.js
import React, { useState } from "react";
import "./App.css";
import { fetchPricesAndWeather } from "./api";
import ChartComponent from "./ChartComponent";

function App() {
  const [zipCode, setZipCode] = useState("");
  const [message, setMessage] = useState("");
  const [prices, setPrices] = useState([]);
  const [weatherData, setWeatherData] = useState(null);
  const [showResults, setShowResults] = useState(false);

  const handleSubmit = async () => {
    console.log("Fetching data for zip code:", zipCode);
    const data = await fetchPricesAndWeather(zipCode);
    console.log("Fetched data:", data);
    if (data) {
      setMessage(
        data.isGoodTime
          ? "(Mocked response) It's a good time to buy heating oil!"
          : "(Mocked response) Now is not a good time to buy heating oil."
      );
      setPrices(data.prices);
      setWeatherData(data.weatherData);
      setShowResults(true);
    } else {
      setMessage("Failed to fetch data. Please try again.");
      setShowResults(false);
    }
  };

  console.log("Rendering App component");

  return (
    <div className="container">
      {!showResults ? (
        <>
          <h1>Heating Oil Price Predictor</h1>
          <p>
            Enter your zip code to find out if now is a good time to buy heating
            oil.
          </p>
          <input
            type="text"
            value={zipCode}
            onChange={(e) => setZipCode(e.target.value)}
            placeholder="Enter your zip code"
          />
          <button onClick={handleSubmit}>Submit</button>
        </>
      ) : (
        <div>
          <h2>Results</h2>
          <p>{message}</p>
          <div>
            <h3>Price Data</h3>
            <ChartComponent data={prices} />
          </div>
          <div>
            <h3>Weather Data</h3>
            {weatherData && weatherData.length > 0 ? (
              <ChartComponent label="Temperature" data={weatherData} />
            ) : (
              <p>No weather data available</p>
            )}
          </div>
          <button onClick={() => setShowResults(false)}>Back</button>
        </div>
      )}
    </div>
  );
}

export default App;
